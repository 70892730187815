.tooltip {

  width: 400px;
  height: 200px;
  border-radius: 10px;
  background: #fff;
  z-index: 2000;
  box-shadow: 0 10px 30px rgb(65 72 86 / 5%);
  position: relative;

  left: -200px;
  top: -210px;
  opacity: 1;
  display: flex;

  &:after {
    content: "";
    width: 20px;
    height: 20px;
    background: #fff;
    border-radius: 2px;
    position: absolute;
    left: 50%;
    margin-left: -10px;
    bottom: -8px;
    transform: rotate(
                    45deg
    );
    z-index: 0;
  }
}

.marker {
  position: relative;
  top: -1em;
  left: -0.375em;
  cursor: pointer;
}

.closeIcon {
  position: absolute;
  top: 0.5em;
  right: 0.5em;
  cursor: pointer;
}

.content {
  padding: 2em;
}
