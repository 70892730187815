.blur{
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #00000080;
  z-index: 50;
  display: flex;
  justify-content: center;
  align-items: center;
}

.box{
  text-align: center;
  padding: 20px;
  width: 80%;
  max-width: 700px;

  background-color: white;
  font-size: 18px;

  display: flex;
  flex-direction: column;

  max-height: 80vh;
  overflow: scroll;
}

.button {
  background-color: #ccc;
  cursor: pointer;
  max-width: 300px;
  width: 100%;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: 2px;
  margin-right: 2px;


  border-radius: 0;
  color: #414141;
  padding: 8px;
  font-size: 16px;
}

.buttonActive {
  background-color: rgb(137, 174, 179);
}

.buttons {
  display: flex;
  justify-content: space-between;
  text-align: center;

  @media screen and (max-width: 870px) {
    flex-direction: column;
    align-items: center;
  }
}



.actionButtons {
  display: flex;
  justify-content: space-between;
  text-align: center;
  border-top: 1px solid rgb(137, 174, 179);
  padding-top: 20px;
  margin-top: 20px;

  button {
    background-color: rgb(254, 184, 161);
  }

  @media screen and (max-width: 870px) {
    flex-direction: column;
    align-items: center;
  }
}



.h1{
  margin-bottom: 5px;
}
