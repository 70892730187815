.slot {
  cursor: pointer;
  text-align: center;
  margin: 3px;
  padding: 5px;
  border: 2px solid #89AEB380;
  border-radius: 3px;
}

.lowAmount {
  color: #243C5760 !important;
}

.fullyBooked {
  color: #ad1f1f !important;
}


.active {
  border-color:#89AEB300 ;
  background-color:#89AEB380 ;
  color: #fff !important;
}

