.body{
  padding: 30px;
  width: 280px;
  border: 2px solid #89AEB360;
  border-radius: 3px;
  margin-bottom: 10px !important;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.textContainer{
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
}

.iconContainer{
  margin-left: 5px;
  margin-right: 40px;
}

.text{
  color: black;
  font-size: 16px
}

.openButton{
  border-width: 0;
  width: 100%;
  background-color: #89AEB3;
  border-radius: 6px;
  color: #414141;
  padding: 10px;
  font-size: 16px
}