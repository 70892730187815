.container {
  font-style: italic;
  font-weight: normal;
  font-size: 14px;
  margin-bottom: 10px;

  @media screen and (max-width: 1023px) {
    font-size: 12px;
  }
}
