.container{
  width: 80%;
}

.calenderContainer{
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
}

.end{
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;
  margin-bottom: 5px;
}

.endTime{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  border: 2px solid #243C57;
  border-radius: 3px;
}

.h1{
  font-size: 45px;
  margin-bottom: 50px;
  color: #89AEB3;
  display: flex;
  justify-content: center;
}

.h2{
  font-size: 22px;
  margin-bottom: 30px;
}

.navBody{
  width: 100%;
  display: flex;
  flex-direction: row;
  border: solid #89AEB3;
  justify-content: space-between;

  border-radius: 8px;
}

.navPart{
  width: 50%;
  text-align: center;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 6px;
  cursor: pointer;
}

.navHeader{
  font-size: 12px;
  margin-bottom: 8px;
}

.sliderContainer{
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
  padding-left:0px;
  padding-right: 0px;
}

.slider{
  width: 95% !important;
  color: #89AEB3!important;
}

.sliderText{
  width: 100%;
  font-family: Futura, sans-serif;
  font-size: 16px;
  text-align: center;
}

.sliderHeader{
  margin-bottom: 5px;
}

.sliderDescription{
  text-align: left;
  font-size: 10px;
  margin-bottom: 38px;
}

.slotContainer{
  width: 100%;
  margin-bottom: 20px
}

.slotHeader{
  font-size: 10px;
  text-align: left;
  margin-bottom: 10px;
}

.buttonContainer{
  display: flex;
  justify-content: center;
  margin-bottom: 30px
}

.button{
  width: 330px;
  border-radius: 0;
  color: #414141;
  padding: 8px;
  font-size: 16px;
}

.range{
  max-width: 300px;
}
