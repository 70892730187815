.container{
  width:80%;
}

.h1{
  font-size: 45px;
  margin: auto auto 50px;
  color: #89AEB3;
  display: flex;
  justify-content: center;
}

.h2{
  font-size: 22px;
  margin-bottom: 40px;
  margin-top: 20px;
  text-align: center;
}
