.container{
  width:80%;
}

.h1{
  font-size: 45px;
  margin: auto auto 20px;
  color: #89AEB3;
  display: flex;
  justify-content: center;
}

.h2{
  font-size: 22px;
  margin-bottom: 20px;
  margin-top: 20px;


}

.center{
  margin-top: 10px;
  text-align: center;
}

.button{
  margin: auto;
  width: 330px;

  background-color: #FEB8A1;
  border-radius: 0;
  color: #414141;
  padding: 8px;
  font-size: 16px;

}

.buttonContainer{
  margin-top: 20px;
  display: flex;
  justify-content: center
}

.text{
  font-size: 14px;
  margin-bottom: 10px;
}

.bold{
  color: #FEB8A1;
  font-size: 13px;
  font-weight: bold;
  margin-bottom: 20px;
}
