body{
  margin: 0;
  font-family: Futura, sans-serif;
  color: #243C57
}

.MuiPickersBasePicker-container {
  justify-content: center;
}

.MuiPickersDay-daySelected {
  background-color: rgb(137, 174, 179) !important;
  span {
    color: #fff !important;
  }
}

.MuiPickersDay-current {
  color: rgb(137, 174, 179) !important;
}

.rdrInRange {
  background-color: rgb(137, 174, 179) !important;
}

.rdrStartEdge, .rdrEndEdge {
  background-color: rgb(137, 174, 179) !important;
}

