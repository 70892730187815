.blur{
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #00000080;
  z-index: 500;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  position: fixed;
  z-index: 600;
  text-align: center;
  padding: 20px;
  width: 80%;
  max-width: 700px;

  background-color: white;
  font-size: 18px;

  display: flex;
  flex-direction: column;

  max-height: 80vh;
  overflow: scroll;
}

.image {
  img {
    width: 50%;
    max-width: 200px;
  }
}

.pinCode {
  font-size: 150%;
}

.stepTitle {
  margin-top: 20px;
}

.button {
  background-color: rgb(137, 174, 179);
  border: none;
  padding: 10px;
  font-size: 1em;
  color: #fff;
  font-weight: bold;
  margin-top: 20px;
}