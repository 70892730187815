.container{
  width: 80%
}

.h1{
  font-size: 45px;
  margin-bottom: 50px;
  color: #89AEB3;
  display: flex;
  justify-content: center;
}

.h2{
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 20px;
}

.list{
  line-height: 1.8;
  font-size: 20px;
}

.formContainer{
  line-height: 5;
  width: 100%
}

.form{
  width: 100%;
}

.button{
  border-radius: 0;
  color: #414141;
  padding: 10px;
  font-size: 14px;
  width: 100%;
  margin-bottom: 20px;
}