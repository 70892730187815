.body{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.h1{
  margin-bottom: 40px;
}

.button{
  margin: 40px auto!important;
  width: 330px;

  background-color: #FEB8A1!important;
  border-radius: 5px;
  color: #414141;
  padding: 8px;
  font-size: 16px;

}

.iconContainer{
  display: flex;
  width: 60%;
  flex: 1;
  justify-content: space-between;
  font-size: 50px;
}